import { library, dom } from '@fortawesome/fontawesome-svg-core';

import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/pro-light-svg-icons';


library.add(
	faBars,
	faFacebookF,
	faInstagram
);

dom.watch();
